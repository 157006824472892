import { Component, OnInit, OnDestroy } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../appointment/appointment.service';

@Component({
  selector: 'app-admin-planning',
  templateUrl: './admin-planning.component.html',
  styleUrls: ['./admin-planning.component.scss']
})
export class AdminPlanningComponent implements OnInit,OnDestroy {

  private sub;
  project_id;
  user;
  showError = false;
  sites;
  site_id;
  constructor(public route:ActivatedRoute, private router:Router,public appointmentService:AppointmentService) { }

  ngOnInit() {

  
  this.initLogin();
}
initLogin() {
  this.loadSites();
this.user = {
  login : "",
  password:""
}
}
loadSites() {
  return this.appointmentService.getSitesOfProject("86a6adc6-ab3e-47ac-8dca-2b3270ee2053").subscribe( (data:any) => {
    this.sites=data.sites;
    this.site_id=this.sites[0].site_id;
  })
}
login() {
  this.appointmentService.login(this.user).subscribe( (data)=> {
    sessionStorage.setItem("logged","log");
    sessionStorage.setItem("type",data["type"]);
    if (data["site"]) {
      this.router.navigate(["/admin/project/86a6adc6-ab3e-47ac-8dca-2b3270ee2053/sites/"+data["site"]]);

    }
    else {
      this.router.navigate(["/admin/project/86a6adc6-ab3e-47ac-8dca-2b3270ee2053/sites/"+this.site_id])
      
    }
  },(error) => {
    console.log("forbidden");
    this.showError = true;
  });
}
ngOnDestroy() {
  if (this.sub) {
    this.sub.unsubscribe();
  }
}

}
