import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
@Injectable()
export class AppointmentService {
    // apiUrl = "http://localhost/backend/module/appointment/api/app.php";
    // imagePath = "http://localhost/_files/module/appointment/";
        apiUrl = "https://coffee.lemon-group.fr:444/backend/module/appointment/api/app.php";
    imagePath = "https://coffee.lemon-group.fr:444/_f1les/appointment/";
    project;
    constructor(private http : HttpClient) {
    }
    getProject(project_token) {
        
        return this.http.get(this.apiUrl + "/project/" + project_token).subscribe((data) => {
             this.project = data;

        });
    }
    login(user) {
        return this.http.post(this.apiUrl+"/login/admin",user)
    }
    getSitesOfProject(project) {
        return this.http.get(this.apiUrl+"/project/"+project+"/sites/planning")
    }



    getAdditonalsOfUser(body) {
        return this.http.post(this.apiUrl+"/additional/user/project",body);
    }
    addAdditionalToUser(body) {
        return this.http.post(this.apiUrl+"/additional/user/project/add",body);
    }
    deleteAdditionalToUser(body) {
        return this.http.post(this.apiUrl+"/additional/user/project/delete",body);
    }

}