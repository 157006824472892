import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NrjService {
      // apiUrl = "http://localhost/backend/module/appointment/api/app.php";
    // imagePath = "http://localhost/_files/module/appointment/";
    apiUrl = "https://coffee.lemon-group.fr:444/backend/module/nrj/api/app.php";
    imagePath = "https://coffee.lemon-group.fr:444/_f1les/appointment/";
    project;

    constructor(private http : HttpClient) {
    }
    getTodayMateriel(body) {
      return this.http.post(this.apiUrl+"/dispatch/list",body)
    }
}

