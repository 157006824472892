import { Injectable } from "@angular/core";
import { TokenService } from "./token.service";



class User {
    user_id : string;
    user_group : number;
    user_name : string;
}

@Injectable()
export class CurrentUserService {
    private user :User;
    constructor(private tokenService:TokenService) {
    }
    getCurrentUser() {
        const token = this.tokenService.getPayload();
        this.user = new User();
        
       this.user.user_id =token.u_id;
       this.user.user_name =token.u_name;
       this.user.user_group =token.u_group;
       return this.user;
    }
 
}