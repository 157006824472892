import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private permissionsService:NgxPermissionsService, private ngxRolesService:NgxRolesService) {
        
    }

    ngOnInit() {
        let user_perm = JSON.parse(sessionStorage.getItem("permissions"));
 
            for (const key in user_perm) {
                if (user_perm.hasOwnProperty(key)) {
                    this.permissionsService.addPermission(key);
                    for (let index = 0; index < user_perm[key].length; index++) {
                        const item = user_perm[key][index];
                        this.permissionsService.addPermission(item);
                    }
    
                    
                }
            }     
        
       
        var permissions = this.permissionsService.getPermissions();

    }
}
