<div class="container" *ngIf="selectedSite">

  <div class="mt-2 mb-2">
    <h2>{{selectedSite.site_name}}</h2>
    <!-- <button class="btn btn-warning" (click)="focus = 'planning';loadSite()" type="button">Afficher le planning</button>&nbsp; -->
<select  (change) ="changeSelected()"  [(ngModel)]="changeSite" name="select_site" id="select-site">
  <option *ngFor="let site of sites" [value] ="site.site_id"> {{site.site_name}}</option>
</select>
</div>
<div *ngIf="total && tech">
  <h4>Matériel à prévoir</h4>
  <p>Nombre d'écran : {{total.ecran}}</p>
  <p>Nombre de portable : {{total.laptop}}</p>
  <p>Nombre de fixe : {{total.desktop}}</p>
  <p>Penser à prévoir quelques matériels supplémentaires </p>
</div>

<div *ngIf="calendarOptions && focus == 'planning'">
  <ng-fullcalendar #ucCalendar [options]="calendarOptions"  (viewRender) ="updateView($event.detail)" (dayClick) ="openDay($event)"  [(eventsModel)]="events"  (eventDrop)="updateEvent($event.detail)"
      (eventResize)="updateEvent($event.detail)" ></ng-fullcalendar>
</div>
</div>