import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';

import { HomeComponent } from './home/home.component';
import { AdminPlanningComponent } from './admin-planning/admin-planning.component';
import { PlanningComponent } from './admin-planning/planning/planning.component';


const routes: Routes = [
    { path: '', component:HomeComponent },
    {path:"admin",component:AdminPlanningComponent},
    {path:"admin/project/:project_id/sites/:site_id",component:PlanningComponent},
    { path: 'access-denied', loadChildren: './access-denied/access-denied.module#AccessDeniedModule' },
    { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
    {path:"form",loadChildren:'./appointment-form/appointment-form.module#AppointmentFormModule'}

    // { path: '**', redirectTo: 'not-found' },

];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash: true}),
],
    exports: [RouterModule]
})
export class AppRoutingModule {}
