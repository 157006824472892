import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Subject } from 'rxjs';


import { Response, Http } from '@angular/http';
import { CurrentUserService } from "./current-user.service";
import { NgxRolesService, NgxPermissionsService } from "ngx-permissions";



class Group {
  group_id: number;
  group_name: string;
}
const apiUrl = "https://coffee.lemon-group.fr:444/backend/system/AUTH/api/app.php"
@Injectable()
export class PermissionsService {
  group: Group;

  constructor(private http: HttpClient, private currentUserService: CurrentUserService, private ngxRolesService: NgxRolesService, private ngxPermissionsService: NgxPermissionsService) {
  }
  setUserGroup() {
    this.getPermissionsGroupName();
    this.ngxRolesService.addRole(this.group.group_name, () => {
      return true
    });
  }

  getPermissionsGroupName() {
    const currentUserGroup = this.currentUserService.getCurrentUser().user_group;
    this.http.get<Group>(apiUrl + "/groups/" + currentUserGroup).subscribe(data => {
      this.group = data;

    })
    return this.group;

  }
}