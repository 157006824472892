import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { TokenService } from './shared/services/token.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {TokenInterceptor} from './shared/services/token.interceptor'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CurrentUserService } from './shared/services/current-user.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import {PermissionsService} from './shared/services/permisions.service';
import { HomeComponent } from './home/home.component';
import { ConfirmModalComponent } from './shared/modules/confirm-modal/confirm-modal.component';
import { AdminPlanningComponent } from './admin-planning/admin-planning.component';
import { PlanningComponent } from './admin-planning/planning/planning.component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { AppointmentService } from './appointment/appointment.service';



// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
        '.json'
    ); */
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        FullCalendarModule
    ],
    declarations: [AppComponent, HomeComponent,ConfirmModalComponent, AdminPlanningComponent, PlanningComponent],
    providers: [AuthGuard,TokenService,{
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      },CurrentUserService,PermissionsService,AppointmentService],
    bootstrap: [AppComponent]
})
export class AppModule {}
