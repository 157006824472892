import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppointmentService } from '../../appointment/appointment.service';

import { CalendarComponent } from 'ng-fullcalendar';
import * as moment from 'moment';
import { NrjService } from '../services/nrj.service';
@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit {

  constructor( private route: ActivatedRoute,private router:Router, private http: HttpClient,public appointmentService:AppointmentService, public _nrj : NrjService) { }

 
  selectedSite: any;
  calendarOptions: object;
  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;
  total;
changeSite;
  events: any;
  techs: any;
  project_id: string;
  site_id: string;
  focus: string;
  pdf: boolean = false;
  today;
  sites;
  tech;
 
  private sub;
  ngOnInit() {
    if (!sessionStorage.getItem("logged") || sessionStorage.getItem("logged")!="log") {
      this.router.navigate(["/admin"]);
    }
    else {

    
    this.sub = this.route.params.subscribe(params => {
      this.project_id = params["project_id"];
      this.site_id = params["site_id"];
      this.focus = "planning";
      this.loadSite();
      this.loadSites();
     
if (sessionStorage.getItem("type") == "tech") {
  this.tech = true;
}
else {
  this.loadSites();
  this.tech=false;
}

    })
  }
  }
  ngOnDestroy() {
    if (this.sub) {

      this.sub.unsubscribe();
    }
  }
  changeSelected() {

    this.router.navigate(["/admin/project/"+this.project_id+"/sites/"+this.changeSite]);
  }
  loadSites() {
    return this.appointmentService.getSitesOfProject(this.project_id).subscribe( (data:any) => {
      this.sites=data.sites;
    })
  }
  loadSite() {
    return this.http.get(this.appointmentService.apiUrl + "/project/" + this.project_id + "/site/" + this.site_id).subscribe(data => {
      this.selectedSite = data;
      this.techs = this.selectedSite.techs;
      this.today = moment().format("YYYY-MM-DD");
      this.loadEvents();
     
    });
  }
  getDispatch() {
    let body = {
      site_name : this.selectedSite.site_name,
      date : "2019-06-19"

    }
    this._nrj.getTodayMateriel(body).subscribe((data) => {
      this.total = data;
    })
  }




  loadEvents() {

    return this.http.get(this.appointmentService.apiUrl + "/events/site/" + this.selectedSite.site_id).subscribe(data => {
      this.events = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          let tech = this.techs.find(function (elt) {
            if (elt.tech_id = data[key].tech_id) {
              return elt;
            }
          })

          const element = data[key];
          let item = {
            id: "",
            start: "",
            end: "",
            title: "",
            color: "",
            tech_id: "",
            info: {}
          };
          item.id = element.event_id;
          item.start = element.event_start;
          item.end = element.event_end;
          item.title = element.event_title;
          item.tech_id = element.tech_id;
          if (typeof element.user === 'object' && element.user !== null) {
            item.info = element.user;
          }

          if (item.title && item.title != null && item.title!="") {

            // item.color = tech.tech_event_color;
            item.color="teal";
          }
          else {
            item.color = "gray";
          }
          this.events.push(item);

        }
      }
      var int = 0;
      var selectedDate = 0;
      for (let index = 0; index < this.selectedSite.days.length; index++) {
        const element = this.selectedSite.days[index].day_date;
        if (element >= this.today) {
          selectedDate = element;
          break;
        }
      }

      if (selectedDate == 0) {
        if (!this.selectedSite.days[0]) {
          selectedDate = this.today;
        }
        else {
          selectedDate = this.selectedSite.days[0].day_date;

        }
      }


      this.calendarOptions = {
        locale: 'fr',
        defaultDate: selectedDate,
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        slotDuration: moment.duration("00:15:00"),
        minTime: moment.duration("08:00:00"), maxTime: moment.duration("18:00:00"),
        editable: false, // disable draggable events
        eventLimit: 3,
        header: {
          left: 'today,prev,next,month,agendaDay',
          center: 'title'
        },
        contentHeight: "auto",
        timeFormat: 'HH:mm',
        allDaySlot: false,
        slotLabelFormat: "HH:mm",
        defaultView: 'agendaDay',
        events: this.events,
   

      };


    })
  }
  openDay(event) {




    this.ucCalendar.fullCalendar('changeView', 'agendaDay');
    this.ucCalendar.fullCalendar('gotoDate', event.date);



  }
  updateView(event) {
    if (this.tech) {
      let body = {
        site_name : this.selectedSite.site_name,
        date : moment(event.view.intervalStart).format("YYYY-MM-DD")
  
      }
      this._nrj.getTodayMateriel(body).subscribe((data) => {
        this.total = data;
      })

    }

  }
  filterEventByDay(day: string) {

    let result = this.events.filter(event => event.start.substring(0, 10) == day);
    result = result.filter(event => event.color != "gray");
    return result;
  }
  filterEventVacant(events) {
    let result = events.filter(event => event.user_id != 0);
    return result;
  }
  exportPDF() {
    let date = this.ucCalendar.fullCalendar('getDate');
    date = moment(date).format("YYYY-MM-DD");
    let events_day = this.filterEventByDay(date);
    let events = this.filterEventVacant(events_day);
    let post = {
      project_id: this.project_id,
      events: events,
      date: date,
      site_id: this.site_id,
    }
    this.http.post(this.appointmentService.apiUrl + "/generatePDF", post).subscribe(data => {   
    })
  }

 

}
