import { Injectable } from "@angular/core";
import * as decode from 'jwt-decode';
const token = sessionStorage.getItem("token");
class Payload {
	iat;exp;u_id;u_name;u_group
            
}

@Injectable()
export class TokenService {
    private user :any;
    private payload :Payload ;
    private token;
    getToken() {
        this.token=sessionStorage.getItem("token")
        return this.token;
    }
    getPayload() {
       return  this.payload=decode(this.getToken());
    }
   getUser() {
       return this.getPayload();
   }

}