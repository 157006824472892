import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
  } from '@angular/common/http';
 
  import { Observable } from 'rxjs';
import { TokenService } from "./token.service";
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private auth: TokenService) {}
    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

  if (this.auth.getToken()) {
     if (!req.headers.get("Authorization")) {
        var cloned = req.clone({
            headers: req.headers.set("Authorization",
                "Bearer " + this.auth.getToken())
        });
     }
    

      return next.handle(cloned);
  }
  else {
      return next.handle(req);
  }
}
  }